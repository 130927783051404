<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="lg"
    :esc-to-close="false"
  >
    <form
      class="flex flex-col gap-2.5"
      @submit="onSubmit"
    >
      <ui-fields-text
        name="text"
        label-two="Text"
        required
        placeholder="Enter mood text"
      />
      <ui-mood-icons-select
        label-two="Icon"
        required
        placeholder="Select icon"
        name="icon"
      />
      <ui-fields-text
        type="color"
        required
        name="color"
        label-two="Color"
      />
      <div class="text-center md:mt-5 mt-3">
        <ui-button
          text="Save"
          type="submit"
        />
      </div>
    </form>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  import { object } from 'yup';

  type Props = {
    initialValues?: Omit<Mood, 'id'>;
  };
  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue', 'success']);
  const { onSuccess } = createModalState(emit);
  const validationFields = useValidationRules();

  const { handleSubmit } = useForm({
    initialValues: {
      text: props.initialValues?.text || '',
      color: props.initialValues?.color || '#604C98',
      icon: props.initialValues?.icon || 'person'
    },
    validationSchema: object({
      text: validationFields.requiredString('Text'),
      color: validationFields.requiredString('Color'),
      icon: validationFields.requiredString('Icon')
    })
  });
  const onSubmit = handleSubmit(values => {
    onSuccess(values);
  });
</script>
