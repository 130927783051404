<template>
  <ui-fields-select
    :options="paginated"
    v-bind="$attrs"
    :select-props="{
      filterable: false
    }"
    @focus="onOpen"
    @blur="onClose"
    @search="query => (search = query)"
  >
    <template #selected-option="{ label }">
      <span class="flex items-center gap-3 capitalize">
        <ui-icon :name="label" />
        {{ label }}
      </span>
    </template>
    <template #option="{ label }">
      <span class="flex items-center gap-3 capitalize">
        <ui-icon :name="label" />
        {{ label }}
      </span>
    </template>
    <template #list-footer>
      <li
        v-show="hasNextPage"
        ref="load"
        class="text-center py-1.5"
      >
        ...
      </li>
    </template>
  </ui-fields-select>
</template>

<script setup lang="ts">
  const apiRoutes = useApiRoutes();
  const limit = ref(20);
  const search = ref('');
  const load = ref();
  let observer: IntersectionObserver | null = null;

  const { data } = await useCacheAsyncData<string[]>('mood-icons', () => getResponseData(apiRoutes.getMoodIcons), {
    server: false,
    default: () => []
  });
  const filtered = computed(() => {
    const s = search.value.trim();
    if (s) {
      return data.value.filter((name: string) => name.includes(s.toLowerCase()));
    }
    return data.value;
  });

  const paginated = computed(() => {
    return filtered.value.slice(0, limit.value);
  });

  const hasNextPage = computed(() => {
    return paginated.value.length < filtered.value.length;
  });

  const onOpen = async () => {
    if (hasNextPage.value && observer) {
      await nextTick();
      observer.observe(load.value);
    }
  };
  const onClose = () => {
    if (observer) {
      observer.disconnect();
    }
  };
  onMounted(() => {
    observer = new IntersectionObserver(async ([{ isIntersecting, target }]) => {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        limit.value += 10;
        await nextTick();
        ul.scrollTop = scrollTop;
      }
    });
  });
</script>
